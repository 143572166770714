
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { defineComponent, ref, onMounted } from "vue";
import ApiService from "@/core/services/ApiService";
import { useI18n } from "vue-i18n";
import { useRoute } from "vue-router";
import { useStore } from "vuex";

interface IStudent {
  _id: string;
  BirthDate: string;
  firstName: string;
  lastName: string;
  gender: string;
  adress: string;
  photo: string;
  mother: {
    fristName: string;
    lastName: string;
    phone: string;
  };
  father: {
    fristName: string;
    lastName: string;
    phone: string;
  };
  classRoomName: "";
}

export default defineComponent({
  name: "profile",
  components: {},

  setup() {
    const { t } = useI18n();
    const store = useStore();

    const route = useRoute();
    const studentID = route.params.id;
    const apiUrl = store.getters.serverConfigUrl.base_url + "/";

    const student = ref<IStudent>({
      _id: "",
      BirthDate: "",
      firstName: "",
      lastName: "",
      gender: "",
      adress: "",
      photo: "",
      mother: {
        fristName: "",
        lastName: "",
        phone: "",
      },
      father: {
        fristName: "",
        lastName: "",
        phone: "",
      },
      classRoomName: "",
    });

    onMounted(async () => {
      setCurrentPageBreadcrumbs(t("student.studentProfile"), []);
      const sy = window.localStorage.getItem("activeSchoolarYear");
      const match = {};
      match["_id"] = studentID;

      await ApiService.post("students/filter", {
        query: { _id: studentID },
        aggregation: [
          {
            $set: {
              classRoom: {
                $convert: {
                  input: "$schoolarYearsHistory." + sy,
                  to: "objectId",
                  onError: null,
                  onNull: null,
                },
              },
            },
          },
          {
            $lookup: {
              from: "classrooms",
              localField: "classRoom",
              foreignField: "_id",
              as: "classRoom",
            },
          },
          {
            $lookup: {
              from: "parents",
              localField: "parent",
              foreignField: "_id",
              as: "parent",
            },
          },
          {
            $project: {
              _id: 1,
              classRoom: {
                $arrayElemAt: ["$classRoom._id", 0],
              },
              classRoomName: {
                $arrayElemAt: ["$classRoom.name", 0],
              },
              father: {
                $arrayElemAt: ["$parent.father", 0],
              },
              mother: {
                $arrayElemAt: ["$parent.mother", 0],
              },
              BirthDate: 1,
              firstName: 1,
              lastName: 1,
              gender: 1,
              photo: 1,
              adress: 1,
            },
          },
        ],
      })
        .then(({ data }) => {
          if (data.length > 0) student.value = data[0];
          else console.error(data);
        })
        .catch((e) => console.log(e));
    });

    return {
      t,
      studentID,
      student,
      apiUrl,
    };
  },
});
